import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Edges from "../edges"
import Typography from "@material-ui/core/Typography"

export default function Navbar({ navbar }) {
  const { navTitle1, navTitle2, navTitle3, navTitle4, navTitle5 } = navbar
  return (
    <Container>
      <Edges size="lg">
        <ContentContainer>
          <StyledLink href={`#section1`}>
            <NavText>{navTitle1}</NavText>
          </StyledLink>
          <StyledLink href={`#section2`}>
            <NavText>{navTitle2} </NavText>
          </StyledLink>
          <StyledLink href={`#section3`}>
            <NavText>{navTitle3} </NavText>
          </StyledLink>
          <StyledLink href={`#section4`}>
            <NavText>{navTitle4} </NavText>
          </StyledLink>
          <StyledLink href={`#section5`}>
            <NavText>{navTitle5} </NavText>
          </StyledLink>
        </ContentContainer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  position: sticky;
  top: 103px;
  background-color: white;
  z-index: 100;
  left: 0;
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const StyledLink = styled.a`
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 50px;

  @media (max-width: 768px) {
    padding-right: 20px;
  }
`

const NavText = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: black;
  text-decoration: none;
  text-transform: capitalize;
`
