import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import CTA from "../../../components/BuilderCampaign/CTA"
import Gallery from "../../../components/BuilderCampaign/Gallery"
import Navbar from "../../../components/BuilderCampaign/Navbar"
import SampleBlocks from "../../../components/BuilderCampaign/SampleBlocks"

// Components

export default function BuilderCampaign(props) {
  const {
    data: {
      wpPage: {
        templateBuilderCampaign: {
          ctaBlock1,
          ctaBlock2,
          ctaBlock3,
          ctaBlock4,
          gallery,
          navbar,
          sampleBlocks,
          sampleBlocks2
        }
      }
    }
  } = props

  return (
    <>
      <Container>
        <CTA
          headline={ctaBlock1.headline}
          healineAlignment={"left"}
          subline={ctaBlock1.subline}
          button={ctaBlock1.button}
          bgColor={"dark"}
          headerVariant={"h2"}
          backgroundImage={ctaBlock1.backgroundImage}
        />
        <Navbar navbar={navbar} />
        <div id="section1">
          <SampleBlocks headline={sampleBlocks.headline} item={sampleBlocks.item} />
        </div>

        <div id="section2">
          <CTA
            headline={ctaBlock3.headline}
            healineAlignment={"left"}
            subline={ctaBlock3.subline}
            button={ctaBlock3.button}
            bgColor={"dark"}
            headerVariant={"h3"}
            backgroundImage={ctaBlock3.backgroundImage}
          />
        </div>
        <div id="section3">
          <SampleBlocks headline={sampleBlocks2.headline} item={sampleBlocks2.item} />
        </div>
        <CTA
          headline={ctaBlock2.headline}
          healineAlignment={"center"}
          subline={ctaBlock2.subline}
          bgColor={"dark"}
          headerVariant={"h3"}
          Wysiwyg={true}
          backgroundImage={ctaBlock2.backgroundImage}
        />

        <div id="section5">
          <Gallery headline={gallery.headline} images={gallery.images} />
        </div>
        <div id="section4">
          <CTA
            headline={ctaBlock4.headline}
            healineAlignment={"left"}
            subline={ctaBlock4.subline}
            formId={ctaBlock4.formId}
            bgColor={"light"}
            headerVariant={"h3"}
          />
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  position: static !important;
  padding-top: 40px;
  top: 0;
  left: 0;
`

export const CollectionQuery = graphql`
  query BuilderCampaignTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSearch
      }
      templateBuilderCampaign {
        ctaBlock1 {
          button {
            target
            title
            url
          }
          fieldGroupName
          headline
          subline
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        ctaBlock2 {
          fieldGroupName
          headline
          subline
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
        ctaBlock3 {
          button {
            target
            title
            url
          }
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          fieldGroupName
          headline
          subline
        }
        ctaBlock4 {
          fieldGroupName
          formId
          headline
          subline
        }
        fieldGroupName
        gallery {
          fieldGroupName
          headline
          images {
            fieldGroupName
            image {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        navbar {
          fieldGroupName
          navTitle1
          navTitle2
          navTitle3
          navTitle4
          navTitle5
        }
        sampleBlocks {
          fieldGroupName
          headline
          item {
            fieldGroupName
            text
            title
            link {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        sampleBlocks2 {
          fieldGroupName
          headline
          item {
            fieldGroupName
            title
            text
            link {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 95, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`
