import React from "react"
import styled from "styled-components"
import Edges from "../edges"
import Typography from "@material-ui/core/Typography"
import Button from "../button"
import Parser from "html-react-parser"
import ArrowRight from "../../../static/images/icons/arrow-right-button.svg"
import Form from "../form"
import Textarea from "../flexibleContent/Textarea"
import BackgroundImage from "../backgroundImage"

export default function CTA({
  backgroundImage,
  headline,
  subline,
  button,
  bgColor,
  healineAlignment,
  headerVariant,
  formId,
  Wysiwyg
}) {
  return (
    <Container bgColor={bgColor}>
      {backgroundImage && <BackgroundImage className="banner-bg-img" image={backgroundImage} />}
      <EdgesContainer size="md">
        <Headline variant={headerVariant} gutterBottom healineAlignment={healineAlignment} bgColor={bgColor}>
          {headline}
        </Headline>
        <ContentContainer>
          {!Wysiwyg ? (
            <Content variant="body1" bgColor={bgColor}>
              {subline && Parser(subline)}
            </Content>
          ) : (
            <WysiwygContainer>{subline && <Textarea text={subline} />}</WysiwygContainer>
          )}
          {button && button.title && (
            <StyledButton color="white" to={button?.url}>
              {Parser(button.title)}
              <div className="arrow-right-container">
                <ArrowRight />
              </div>
            </StyledButton>
          )}
          {formId && (
            <FormContainer>
              <Form formId={formId} hideTitle={true} footerForm={true} />
              <div className="arrow-right-container">
                <ArrowRight />
              </div>
            </FormContainer>
          )}
        </ContentContainer>
      </EdgesContainer>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${(props) => (props.bgColor === "light" ? "white" : "#969696")};
  position: relative;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: ${(props) =>
      props.bgColor === "light" ? "" : "linear-gradient(to top, rgba(0, 0, 0, 0.60) 70%, rgba(0, 0, 0, 0) 100%)"};
  }
  .banner-bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
  }
`
const EdgesContainer = styled(Edges)`
  padding-top: 80px;
  padding-bottom: 80px;
  height: 100%;
  position: relative;
  z-index: 11;
`
const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* padding-left: ${(props) => (props.paddingCustome === "h2" ? "10%" : "0px")};
  padding-right: ${(props) => (props.paddingCustome === "h2" ? "10%" : "0px")}; */
  justify-content: center;
  @media (min-width: 450px) {
    justify-content: space-between;
  }
`

const Headline = styled(Typography)`
  color: ${(props) => (props.bgColor === "light" ? "black" : "white")};
  margin-bottom: 20px;
  max-width: 800px;
  text-transform: capitalize;
  margin-left: ${(props) => (props.healineAlignment === "center" ? "auto" : "0")};
  margin-right: ${(props) => (props.healineAlignment === "center" ? "auto" : "0")};
  text-align: center;
  @media (min-width: 450px) {
    text-align: ${(props) => (props.healineAlignment === "center" ? "center" : "left")};
  }
`

const Content = styled(Typography)`
  max-width: 450px;
  color: ${(props) => (props.bgColor === "light" ? "black" : "white")};
  text-align: center;
  padding-bottom: 20px;
  @media (min-width: 450px) {
    text-align: left;
    padding-bottom: 0px;
  }
`
const WysiwygContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: ${(props) => (props.bgColor === "light" ? "black !important" : "white !important")};
    max-width: 860px;
  }
`
const StyledButton = styled(Button)`
  && {
    border: none;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 15px 20px;
    @media (min-width: 900px) {
      padding: 15px 50px;
    }
    border-radius: 0px;
    background: white;
    color: black;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    .arrow-right-container {
      padding-left: 10px;
    }

    &:hover {
      .arrow-right-container > svg > path {
        fill: white;
      }
    }
    svg {
      margin-left: 10px;
    }
    span {
      transform: translateY(1px);
    }
  }
`

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 380px;
  .arrow-right-container {
    position: absolute;
    right: 20px;
    top: 18px;
    z-index: 10;
    pointer-events: none;
    svg > path {
      fill: white;
    }
  }
  form {
    border: 1px solid black;
    .form-group {
      margin-bottom: 0px !important;
      max-width: 380px;
    }
    input {
      max-width: 380px;
    }
    input::placeholder {
    }

    display: flex;
    justify-content: center;
    align-items: center;
    button {
      height: 42px;
      background-color: black !important;
      color: white !important;
      span {
        display: none !important;
      }
      @media screen and (max-width: 420px) {
        margin-left: 0px;
      }
      &:hover {
        &:before {
          display: none !important;
        }
      }
    }
    label {
      display: none;
    }

    p {
      display: none !important;
    }
  }
  .gform_confirmation_message_56 {
    color: #00b040 !important;
  }
`
