import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Edges from "../edges"
import { Link } from "gatsby"
import { getUrlPath } from "utils/getUrlPath"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

export default function SampleBlocks({ headline, item }) {
  return (
    <Container size="md">
      {headline && (
        <Headline variant="h2" gutterBottom>
          {Parser(headline)}
        </Headline>
      )}
      <ContentContainer>
        {item &&
          item.length > 0 &&
          item.map((box, index) => {
            const blocklImage = box?.image && getImage(box?.image.localFile)
            return (
              <Box key={index} to={getUrlPath(box?.link?.url)}>
                <Image>
                  <GatsbyImage image={blocklImage} alt={box?.image.altText} />
                </Image>
                {box.title && <BoxTitle>{Parser(box.title)}</BoxTitle>}
                {box.text && <BoxText>{Parser(box.text)}</BoxText>}
              </Box>
            )
          })}
      </ContentContainer>
    </Container>
  )
}

const Container = styled(Edges)`
  margin-top: 30px;
  margin-bottom: 30px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`
const Box = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100%;
  @media (max-width: 975px) {
    width: 50%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  margin-bottom: 30px;
`

const BoxTitle = styled(Typography)`
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    max-width: 300px;
  }
  @media (max-width: 576px) {
    max-width: 100%;
  }
`

const BoxText = styled(Typography)`
  max-width: 220px;
  text-align: center;
  font-size: 16px;

  @media (max-width: 768px) {
    max-width: 220px;
  }
  @media (max-width: 576px) {
    max-width: 80%;
    text-align: center;
  }
`

const Headline = styled(Typography)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 40px;
`

const Image = styled.div`
  margin-bottom: 20px;
  position: relative;
  object-fit: cover !important;
  width: 220px;
  height: 150px;
  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
    background-position: center !important;
    object-fit: cover !important;
  }
`
