import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Edges from "../edges"
import Typography from "@material-ui/core/Typography"
import Parser from "html-react-parser"
import { useStore } from "store"

export default function Gallery({ headline, images }) {
  const [, dispatch] = useStore()

  return (
    <Container size="md">
      {headline && (
        <Headline variant="h2" gutterBottom>
          {Parser(headline)}
        </Headline>
      )}
      <ContentContainer>
        {images &&
          images.length > 0 &&
          images.map((item, index) => {
            const gallerylImage = item?.image && getImage(item?.image.localFile)
            return (
              <Image
                key={index}
                onClick={() =>
                  dispatch({
                    type: "SET_LIGHTBOX",
                    payload: {
                      open: true,
                      slide: 0,
                      slides: [
                        {
                          link: null,
                          image: item?.image?.sourceUrl
                        }
                      ],
                      options: {
                        thumbs: false
                      }
                    }
                  })
                }
              >
                <GatsbyImage image={gallerylImage} alt={item?.image.altText} />
              </Image>
            )
          })}
      </ContentContainer>
    </Container>
  )
}

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const Container = styled(Edges)`
  margin-top: 30px;
  margin-bottom: 30px;
`

const Headline = styled(Typography)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
`

const Image = styled.div`
  cursor: pointer;
  position: relative;
  object-fit: cover !important;
  width: 100%;
  height: 240px;
  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
    background-position: center !important;
    object-fit: cover !important;
  }
`
